import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { ContactSchema } from '@/types/forms'
import { messages } from '@/config/messages'
import { contactFormVariant } from './contact-form.variants'
import { postContact } from '@/services/contact'
import { toast } from 'sonner'

const schema = z.object({
  name: z.string().min(1, { message: messages.validation.required }),
  email: z
    .string()
    .min(1, { message: messages.validation.required })
    .email({ message: messages.validation.email }),
  phone: z.string().min(1, { message: messages.validation.required }),
  body: z.string().min(1, { message: messages.validation.required })
})

export const useContactForm = () => {
  const styles = contactFormVariant()

  const form = useForm<ContactSchema>({ resolver: zodResolver(schema) })

  const onSubmit: SubmitHandler<ContactSchema> = async (data) => {
    const res = await postContact(data)

    if (!res.success) {
      toast.error(res.message || 'Ops')
      return
    }

    toast.success(res.message || 'Enviado com sucesso!')
    form.reset()
  }

  return {
    styles,
    form,
    onSubmit
  }
}
