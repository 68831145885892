import { MouseEventHandler, Ref, useCallback, useMemo } from 'react'
import { ButtonProps as AriaButtonProps } from 'react-aria-components'
import { PressEvent } from '@react-types/shared'
import { SvgDetails } from '@/types/icon'
import { GoogleEventProps, dispatchGoogleEvent } from '@/utils/google'
import { ButtonVariantProps, button } from '@/theme/components/button'

export type useButtonProps = Omit<AriaButtonProps, 'className'> &
  ButtonVariantProps & {
    ref?: Ref<HTMLButtonElement>
    className?: string
    startIcon?: SvgDetails
    startIconClassName?: string
    endIcon?: SvgDetails
    endIconClassName?: string
    googleEvent?: GoogleEventProps
    disabled?: boolean
    loading?: boolean
    loadingLabel?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
  }

export const useButton = (props: useButtonProps) => {
  const {
    ref: buttonRef,
    className = '',
    children,
    startIcon,
    startIconClassName = '',
    endIcon,
    endIconClassName = '',
    variant = 'solid',
    size = 'md',
    color = 'dark',
    weight = 'medium',
    rounded = false,
    fullWidth = false,
    uppercase = false,
    disabled = false,
    loading = false,
    loadingLabel,
    isBetween = false,
    googleEvent,
    onPress,
    onClick,
    ...otherProps
  } = props

  const isLoading = loading
  const isIconOnly = !children && (!!startIcon || !!endIcon)
  const isDisabled = disabled || isLoading

  const styles = useMemo(() => {
    return button({
      variant,
      size,
      color,
      weight,
      rounded,
      fullWidth,
      isIconOnly,
      isBetween,
      uppercase
    })
  }, [
    variant,
    size,
    color,
    weight,
    rounded,
    fullWidth,
    isIconOnly,
    isBetween,
    uppercase
  ])

  const handlePress = useCallback(
    (e: PressEvent) => {
      if (!!googleEvent) {
        const { eventName, eventParams } = googleEvent
        dispatchGoogleEvent(eventName, eventParams)
      }

      if (!!onClick) {
        // @ts-ignore
        onClick(e)
      }

      if (!!onPress) {
        onPress(e)
      }
    },
    [onPress, onClick, googleEvent]
  )

  return {
    buttonRef,
    className,
    children,
    styles,
    startIcon,
    startIconClassName,
    endIcon,
    endIconClassName,
    isLoading,
    loadingLabel,
    handlePress,
    componentProps: {
      isDisabled,
      ...otherProps
    }
  }
}
