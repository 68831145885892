import { LabelHTMLAttributes, useMemo } from 'react'
import { TextProps } from '@/components/ui/text'
import { LabelVariantProps, label } from './label.variants'

export type useLabelProps = LabelVariantProps & {
  className?: string
  size?: 'sm' | 'md'
} & LabelHTMLAttributes<HTMLLabelElement> &
  Pick<TextProps, 'weight' | 'color' | 'uppercase'>

export const useLabel = (props: useLabelProps) => {
  const {
    className = '',
    children,
    size = 'md',
    weight = 'semibold',
    hidden = false,
    ...componentProps
  } = props

  const styles = useMemo(() => label({ hidden }), [hidden])

  return {
    className,
    children,
    size,
    weight,
    styles,
    componentProps
  }
}
