import { gaTrackId, isBrowser } from './env'

export interface GoogleEventProps {
  eventName: string
  eventParams: Record<string, unknown>
}

export const dispatchGoogleEvent = (
  eventName: GoogleEventProps['eventName'],
  eventParams: GoogleEventProps['eventParams'] | string
) => {
  if (!isBrowser || !gaTrackId) return

  const params =
    typeof eventParams === 'string' ? { label: eventParams } : eventParams

  if (typeof gtag !== 'undefined') {
    gtag('event', eventName, params)
  }
}

export const pageView = (url: string) => {
  if (typeof gtag !== 'undefined') {
    gtag('config', gaTrackId, {
      page_path: url
    })
  }
}
