'use client'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/data/input'
import { TextArea } from '@/components/data/textarea'
import { useContactForm } from './use-contact-form'
import { Link } from '@/components/ui/link'

const ContactForm = () => {
  const { form, onSubmit, styles } = useContactForm()

  return (
    <div className={styles.base()}>
      <form
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        className={styles.form()}
      >
        <Input
          {...form.register('name')}
          className="col-span-2"
          label="Nome"
          error={form.formState.errors.name}
        />
        <Input
          {...form.register('email')}
          className="col-span-2 sm:col-span-1"
          type="email"
          label="E-mail"
          error={form.formState.errors.email}
        />
        <Input
          {...form.register('phone')}
          className="col-span-2 sm:col-span-1"
          type="tel"
          label="Telefone"
          error={form.formState.errors.phone}
        />
        <TextArea
          {...form.register('body')}
          className="col-span-2"
          label="Mensagem"
          placeholder="Escreva sua mensagem..."
          rows={4}
          error={form.formState.errors.body}
        />
        <div className="col-span-2">
          <Button
            className={styles.send()}
            type="submit"
            loading={form.formState.isSubmitting}
            loadingLabel="Enviando..."
            variant="solid"
            weight="semibold"
            color="primary"
            size="lg"
            fullWidth
            rounded
          >
            Enviar mensagem
          </Button>
        </div>
      </form>
      <span className={styles.divider()}>ou</span>
      <Link
        startIcon="social-whatsapp"
        title="Falar pelo WhatsApp"
        href="https://wa.me/5583988843682"
        size="lg"
        variant="outline"
        weight="semibold"
        color="primary"
        fullWidth
        rounded
      >
        Falar pelo WhatsApp
      </Link>
    </div>
  )
}

export default ContactForm
