import { tv, VariantProps } from '@/utils/tv'

export const label = tv({
  slots: {
    base: []
  },
  variants: {
    hidden: {
      true: {
        base: ['hidden']
      }
    }
  },
  defaultVariants: {
    size: 'md',
    hidden: false
  }
})

export type LabelVariantProps = VariantProps<typeof label>
