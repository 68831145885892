class CockpitError extends Error {
  params?: Record<string, unknown>

  constructor(message: string, params = {}) {
    super(message)

    this.name = 'CockpitError'
    this.params = params

    Object.setPrototypeOf(this, CockpitError.prototype)
  }
}

export { CockpitError }
