import { forwardRef } from 'react'
import { Label } from '@/components/ui/label'
import { HelpText } from '@/components/ui/help-text'
import { useTextAreaProps, useTextArea } from './use-textarea'

export type TextAreaProps = useTextAreaProps

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const {
      className,
      fieldId,
      label,
      hideLabel,
      hasError,
      errorMessage,
      styles,
      componentProps
    } = useTextArea({ ...props, ref })

    return (
      <div className={styles.base({ className })}>
        {!!label && (
          <Label
            htmlFor={fieldId}
            hidden={hideLabel}
            className={styles.label()}
          >
            {label}
          </Label>
        )}
        <div className={styles.group()}>
          <textarea
            id={fieldId}
            className={styles.textarea()}
            {...componentProps}
          />
        </div>
        {hasError && <HelpText status="danger">{errorMessage}</HelpText>}
      </div>
    )
  }
)

TextArea.displayName = 'TextArea'

export default TextArea
