import { InputHTMLAttributes, ForwardedRef, useMemo, ChangeEvent } from 'react'
import { FieldError } from 'react-hook-form'
import { Mask, masks } from '@/utils/masks'
import { SvgName } from '@/svg'
import { TextProps } from '@/components/ui/text'
import { InputVariantProps, input } from './input.variants'

export type useInputProps = InputVariantProps & {
  ref: ForwardedRef<HTMLInputElement>
  className?: string
  label?: string
  labelWeight?: TextProps['weight']
  description?: string
  startContent?: React.ReactNode | SvgName
  endContent?: React.ReactNode | SvgName
  mask?: Mask
  error?: FieldError
  hideLabel?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const useInput = (props: useInputProps) => {
  const {
    ref,
    className = '',
    id,
    name,
    description,
    label,
    labelWeight,
    hideLabel = false,
    type = 'text',
    appearance = 'outline',
    mask,
    startContent,
    endContent,
    onChange,
    error,
    ...componentProps
  } = props

  const fieldId = id || name
  const hasError = !!error

  const styles = useMemo(
    () =>
      input({
        appearance,
        hasStartContent: !!startContent,
        hasEndContent: !!endContent,
        hasError
      }),
    [appearance, startContent, endContent, hasError]
  )

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (mask && masks[mask]) {
      event.target.value = masks[mask](value)
    }

    onChange && onChange(event)
  }

  return {
    fieldId,
    className,
    description,
    label,
    labelWeight,
    hideLabel,
    hasError,
    startContent,
    endContent,
    errorMessage: error?.message,
    styles,
    componentProps: {
      ...componentProps,
      ref,
      name,
      type,
      onChange: handleOnChange
    }
  }
}
