import { useMemo, useRef, useState } from 'react'
import { type YouTubeProps, type YouTubePlayer } from 'react-youtube'
import {
  YoutubeVideoVariantProps,
  youtubeVideo
} from './youtube-video.variants'

export type useYoutubeVideoProps = Omit<YoutubeVideoVariantProps, 'playing'> & {
  className?: string
  cover: string
  title: string
  videoId: string
}

export const useYoutubeVideo = (props: useYoutubeVideoProps) => {
  const { className = '', rounded = 'lg', cover, title, videoId } = props

  const [playing, setPlaying] = useState(false)
  const ref = useRef<YouTubePlayer>(null)

  const styles = useMemo(
    () => youtubeVideo({ playing, rounded }),
    [playing, rounded]
  )

  const opts: YouTubeProps['opts'] = {
    playerVars: {
      controls: 0,
      iv_load_policy: 3,
      listType: 'user_uploads',
      rel: 0,
      modestbranding: 1,
      showinfo: 0
    }
  }

  const onClick = () => {
    const player = ref.current

    if (player) {
      player.getInternalPlayer().playVideo()
    }
  }

  const onPlay: YouTubeProps['onPlay'] = () => {
    setPlaying(true)
  }

  const onEnd: YouTubeProps['onEnd' | 'onPause'] = () => {
    setPlaying(false)
  }

  return {
    ref,
    className,
    styles,
    cover,
    title,
    videoId,
    opts,
    onPlay,
    onEnd,
    onClick
  }
}
