import { useMemo } from 'react'
import { SpinnerVariantProps, spinner } from './spinner.variants'

export type useSpinnerProps = SpinnerVariantProps & {
  className?: string
  label?: string
}

export const useSpinner = (props: useSpinnerProps) => {
  const { className = '', label, size = 'sm', color, ...componentProps } = props

  const styles = useMemo(() => spinner({ size, color }), [size, color])

  return {
    className,
    styles,
    label,
    componentProps
  }
}
