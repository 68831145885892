import { isLastChar, onlyNumbers } from './number'

const masks = {
  postalcode: (value: string) => {
    const digits = onlyNumbers(value)

    return digits
      .slice(0, 8)
      .split('')
      .reduce((acc, digit, i) => {
        const result = `${acc}${digit}`

        if (!isLastChar(i, digits)) {
          if ([4].indexOf(i) >= 0) return `${result}-`
        }

        return result
      }, '')
  },
  phone: (value: string) => {
    const digits = onlyNumbers(value)

    return digits
      .slice(0, 11)
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
  },
  lowercase: (value: string) => {
    return value.toLowerCase()
  },
  cpf: (value: string) => {
    const digits = onlyNumbers(value)

    return digits
      .slice(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  },
  cnpj: (value: string) => {
    const digits = onlyNumbers(value)

    return digits
      .slice(0, 14)
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
  },
  date: (value: string) => {
    const digits = onlyNumbers(value)

    return digits
      .slice(0, 8)
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d{2})$/, '$1$2')
  },
  currency: (value: string) => {
    const digits = onlyNumbers(value || 0)
    const numericValue = parseInt(digits, 10)

    return numericValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 0
    })
  }
}

export type Mask = keyof typeof masks

export { masks }
