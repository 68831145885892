import { forwardRef } from 'react'
import { Label } from '@/components/ui/label'
import { HelpText } from '@/components/ui/help-text'
import { useInput, useInputProps } from './use-input'

export type InputProps = useInputProps

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    fieldId,
    label,
    labelWeight,
    hideLabel,
    hasError,
    startContent,
    endContent,
    errorMessage,
    styles,
    componentProps
  } = useInput({ ...props, ref })

  return (
    <div className={styles.base({ className })}>
      {!!label && (
        <Label
          htmlFor={fieldId}
          hidden={hideLabel}
          weight={labelWeight}
          className={styles.label()}
        >
          {label}
        </Label>
      )}
      <div className={styles.group()}>
        {!!startContent && (
          <span className={styles.icon({ className: 'left-3' })}>
            {startContent}
          </span>
        )}
        <input id={fieldId} className={styles.input()} {...componentProps} />
        {!!endContent && (
          <span className={styles.icon({ className: 'right-3' })}>
            {endContent}
          </span>
        )}
      </div>
      {hasError && <HelpText status="danger">{errorMessage}</HelpText>}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
