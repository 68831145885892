import { tv, VariantProps } from '@/utils/tv'

export const textarea = tv({
  slots: {
    base: ['relative', 'group', 'flex flex-col'],
    label: ['mb-2'],
    group: ['relative', 'flex items-center gap-x-2'],
    textarea: [
      'block',
      'w-full',
      'py-3 px-4',
      'appearance-none',
      'resize-none',
      'outline-none',
      'rounded-md',
      'placeholder:text-gray-400'
    ]
  },
  variants: {
    appearance: {
      outline: {
        textarea: [
          'border-gray-200',
          'bg-white',
          'focus:border-pink-500 focus:ring focus:ring-pink-500/30'
        ],
        icon: ['absolute inset-y-0 m-auto']
      },
      ghost: {
        textarea: [
          'px-0',
          'bg-transparent border-transparent',
          'focus:outline-none focus:border-transparent focus:ring-0'
        ]
      }
    },
    color: {
      dark: {
        icon: 'text-gray-400',
        label: 'text-gray-400'
      },
      light: ''
    },
    hasError: {
      true: ''
    }
  },
  defaultVariants: {
    appearance: 'outline',
    color: 'dark',
    hasError: false
  },
  compoundVariants: [
    {
      appearance: 'outline',
      hasError: true,
      className: {
        textarea: ['border-red-500', 'focus:border-red-400 focus:ring-red-100']
      }
    },
    {
      appearance: 'outline',
      hasStartContent: true,
      className: {
        textarea: ['pl-10']
      }
    },
    {
      appearance: 'outline',
      hasEndContent: true,
      className: {
        textarea: ['pr-10']
      }
    }
  ],
  compoundSlots: [
    {
      slots: ['label', 'textarea'],
      hasError: true,
      className: 'text-red-500'
    }
  ]
})

export type TextAreaVariantProps = Omit<
  VariantProps<typeof textarea>,
  'hasError'
>
