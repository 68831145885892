import { Text } from '@/components/ui/text'
import { useLabel, useLabelProps } from './use-label'

export type LabelProps = useLabelProps

const Label = (props: LabelProps) => {
  const { className, children, size, weight, styles, componentProps } =
    useLabel(props)

  return (
    <Text
      as="label"
      className={styles.base({ className })}
      size={`label-${size}`}
      weight={weight}
      color="text-purple-500"
      {...componentProps}
    >
      {children}
    </Text>
  )
}

export default Label
