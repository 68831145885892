import { Children } from 'react'
import { replaceWithMarkup } from '@/utils/text'
import { useText, useTextProps } from './use-text'

export type TextProps = useTextProps

const Text = (props: TextProps) => {
  const {
    Component,
    className,
    children,
    color = '',
    secondColor,
    styles,
    componentProps
  } = useText(props)

  return (
    <Component
      className={styles.base({ className: `${className} ${color}` })}
      {...componentProps}
    >
      {Children.map(children, (child) => {
        if (
          typeof child === 'string' &&
          (child.includes('|') || child.includes('**'))
        ) {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: replaceWithMarkup(child, secondColor)
              }}
            />
          )
        }

        return child
      })}
    </Component>
  )
}

export default Text
