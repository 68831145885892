import { tv, VariantProps } from '@/utils/tv'

export const contactFormVariant = tv({
  slots: {
    base: ['flex flex-col gap-6 shrink-0', 'w-full max-w-lg'],
    form: ['grid grid-cols-1 md:grid-cols-2 gap-6'],
    send: [],
    divider: [
      'relative',
      'block',
      'text-center',
      'font-bold',
      'text-purple-500',
      'before:content-[""] after:content-[""]',
      'before:absolute after:absolute',
      'before:left-0 before:top-1/2 after:right-0 after:top-1/2',
      'before:h-[1px] after:h-[1px]',
      'before:w-2/5 after:w-2/5',
      'before:bg-gray-200 after:bg-gray-200'
    ]
  }
})

export type ContactFormVariantProps = VariantProps<typeof contactFormVariant>
