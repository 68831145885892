import { useMemo } from 'react'
import { TextVariantProps, text } from './text.variants'

export type useTextProps = TextVariantProps & {
  as?:
    | 'p'
    | 'span'
    | 'small'
    | 'strong'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'time'
    | 'cite'
    | 'q'
    | 'em'
    | 'label'
    | 'address'
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: {
    __html: string | TrustedHTML
  }
  color?: string
  secondColor?: string
}

export const useText = (props: useTextProps) => {
  const {
    as = 'p',
    className = '',
    children,
    size = 'body-lg',
    weight,
    uppercase = false,
    italic,
    notItalic,
    color,
    secondColor,
    ...componentProps
  } = props

  const styles = useMemo(
    () => text({ size, weight, uppercase, italic, notItalic }),
    [size, weight, uppercase, italic, notItalic]
  )

  return {
    Component: as,
    className,
    children,
    color,
    secondColor,
    styles,
    componentProps
  }
}
