import { TextareaHTMLAttributes, ForwardedRef, useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { TextAreaVariantProps, textarea } from './textarea.variants'

export type useTextAreaProps = TextAreaVariantProps & {
  ref: ForwardedRef<HTMLTextAreaElement>
  className?: string
  label?: string
  description?: string
  error?: FieldError
  hideLabel?: boolean
} & TextareaHTMLAttributes<HTMLTextAreaElement>

export const useTextArea = (props: useTextAreaProps) => {
  const {
    ref,
    className = '',
    id,
    name,
    description,
    label,
    rows = 3,
    hideLabel = false,
    appearance = 'outline',
    error,
    ...componentProps
  } = props

  const fieldId = id || name
  const hasError = !!error

  const styles = useMemo(
    () =>
      textarea({
        appearance,
        hasError
      }),
    [appearance, hasError]
  )

  return {
    fieldId,
    className,
    description,
    label,
    hideLabel,
    hasError,
    errorMessage: error?.message,
    styles,
    componentProps: {
      ...componentProps,
      ref,
      name,
      rows
    }
  }
}
