'use client'

import { ForwardedRef, forwardRef, useId } from 'react'
import { Button as ButtonAria } from 'react-aria-components'
import { Spinner } from '@/components/ui/spinner'
import { Vector } from '@/components/ui/vector'
import { useButton, useButtonProps } from './use-button'

export type ButtonProps = useButtonProps

const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const id = useId()
    const {
      buttonRef,
      styles,
      className,
      children,
      isLoading,
      loadingLabel,
      startIcon,
      startIconClassName,
      endIcon,
      endIconClassName,
      handlePress,
      componentProps
    } = useButton({
      ...props,
      ref
    })

    return (
      <ButtonAria
        {...componentProps}
        data-test={id}
        ref={buttonRef}
        onPress={handlePress}
        className={styles.base({ className })}
      >
        <>
          {isLoading && (
            <>
              <Spinner className={styles.icon()} label={loadingLabel} />
              {loadingLabel}
            </>
          )}
          {!isLoading && (
            <>
              {!!startIcon && (
                <Vector
                  className={styles.icon({ className: startIconClassName })}
                  name={
                    typeof startIcon === 'string' ? startIcon : startIcon.name
                  }
                />
              )}
              {children}
              {!!endIcon && (
                <Vector
                  className={styles.icon({ className: endIconClassName })}
                  name={typeof endIcon === 'string' ? endIcon : endIcon.name}
                />
              )}
            </>
          )}
        </>
      </ButtonAria>
    )
  }
)

Button.displayName = 'Button'

export default Button
