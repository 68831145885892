import { VariantProps, tv } from '@/utils/tv'

export const youtubeVideo = tv({
  slots: {
    base: ['group/item', 'relative', 'overflow-hidden', 'w-full'],
    cover: [
      'absolute z-20',
      'w-full h-full',
      'cursor-pointer',
      'transition-opacity duration-300'
    ],
    image: ['object-cover object-center'],
    button: [
      'absolute inset-0 z-20',
      'rounded-full',
      'flex items-center justify-center',
      'w-20 h-20',
      'm-auto',
      'bg-pink-500',
      'group-hover/item:scale-110',
      'transition-transform duration-300'
    ],
    icon: ['w-5 h-5', 'translate-x-0.5', 'text-white'],
    player: ['aspect-w-16 aspect-h-9'],
    iframe: []
  },
  variants: {
    playing: {
      true: {
        cover: ['opacity-0', 'pointer-events-none']
      }
    },
    rounded: {
      md: {
        base: 'rounded-md',
        iframe: 'rounded-md'
      },
      lg: {
        base: 'rounded-lg',
        iframe: 'rounded-lg'
      },
      xl: {
        base: 'rounded-xl',
        iframe: 'rounded-xl'
      }
    }
  },
  defaultVariants: {
    playing: false,
    rounded: 'lg'
  }
})

export type YoutubeVideoVariantProps = VariantProps<typeof youtubeVideo>
