import { useMemo } from 'react'
import { HelpTextVariantProps, helpText } from './help-text.variants'

export type useHelpTextProps = HelpTextVariantProps & {
  className?: string
  children?: React.ReactNode
}

export const useHelpText = (props: useHelpTextProps) => {
  const {
    className = '',
    children,
    status = 'default',
    floating = true,
    ...componentProps
  } = props

  const styles = useMemo(
    () => helpText({ status, floating }),
    [status, floating]
  )

  return {
    className,
    children,
    status,
    styles,
    componentProps
  }
}
