'use client'

import NextImage from 'next/image'
import YouTube from 'react-youtube'
import { Vector } from '@/components/ui/vector'
import { useYoutubeVideo, useYoutubeVideoProps } from './use-youtube-video'

export type YoutubeVideoProps = useYoutubeVideoProps

const YoutubeVideo = (props: YoutubeVideoProps) => {
  const {
    ref,
    styles,
    className,
    title,
    cover,
    videoId,
    opts,
    onClick,
    onPlay,
    onEnd
  } = useYoutubeVideo(props)

  return (
    <div className={styles.base({ className })}>
      <div className={styles.cover()} onClick={onClick}>
        <span className={styles.button()}>
          <Vector name="play" className={styles.icon()} />
        </span>
        <NextImage
          fill
          src={cover}
          alt={title}
          className={styles.image()}
          sizes="(max-width: 768px) 100vw, 50vw"
        />
      </div>
      <YouTube
        className={styles.player()}
        title={title}
        iframeClassName={styles.iframe()}
        videoId={videoId}
        opts={opts}
        onPlay={onPlay}
        onEnd={onEnd}
        ref={ref}
      />
    </div>
  )
}

export default YoutubeVideo
